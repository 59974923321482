#ImgUpdate {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    gap: 1rem;

   
   
}
#ImgUpdate div {
    width: 20rem;
    border-radius:2rem ;
    box-shadow: solid black 2px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-bottom: 2rem;

}
#ImgUpdate span {
    font-size:24px ;
    font-weight: 600;
    margin-top: 1rem;
}


#ImgUpdate img {
   width: 100%;
}

#employe {
    width: 50rem;
}

#employe img {
    width: 100%;
}

#popover {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    margin-bottom: 2rem;
}

@media screen and (width>=920px){

    #Galsect1 {
        margin-top: 135px;
        background-color: #003393;
        color: #fff;
    
    }
    #Galprev {
       
        width: 1152px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        
    }
    #Galeft {
        display: flex;
        flex-direction: column;
        width: 45%;
        margin-top: 100px;
        margin-bottom: 100px;
    }
    #Galeft h1 {
        font-size: 48px;
        line-height: 57px;
        font-weight:700;
        margin-bottom: 60px;
    }
    #Nos {
        display: inline-block;
        font-size: 36px;
        line-height: 42px;
        font-weight: 100;
    
    }
    #Garight {
        width: 50%;
        position: relative;
        align-self: flex-start;
    }
    #Garight img {
        position: absolute;
        top: 0;
    }
    #Galsect2 {
        width: 1152px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }
    #btngals2 {
        margin-top: 20px;
    }
    #roster {
        margin-top: 120px;
        display: flex;
        justify-content: space-around;
        justify-self: center;
        margin-bottom: 120px;
    }
    #roster div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        
    }
    #roster div span{
        font-size:24px ;
        font-weight: 600;
        margin-top: 1rem;
    
        
    }
    }
@media  screen and (width<920px) and (width>468px) {

    #Galsect1 {
        margin-top: 4rem;
        background-color: #003393;
        color: #fff;
    
    }
    #Galprev {
       
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0rem 1rem;
        
    }
    #Galeft {
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
        margin-bottom: 2rem;
        width: 100%;
       
    }
    #Galeft h1 {
        font-size: 48px;
        line-height: 57px;
        font-weight:700;
        margin-bottom: 1rem;
    }
    #Nos {
        display: inline-block;
        font-size: 36px;
        line-height: 42px;
        font-weight: 100;
       
    
    }
    #Garight {
        width: 100%;
        position: relative;
        align-self: flex-start;
        display: contents;
    }
    #Garight img {
       width: 100%;
        top: 0;
    }
    #Galsect2 {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }
    #btngals2 {
        background-color: #003393;
        width: 100%;
        padding: 2rem 35vw;
        align-self: center;
        text-align: center;
    }
    #roster {
        margin-top: 120px;
        display: flex;
        justify-content: space-around;
        justify-self: center;
        margin: 2rem 1rem;
    }
    #roster div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 4rem;
        cursor: pointer;

        
    }
    #roster div img[alt='Nos équipes'] {
        width: 70%;
    }
    #roster div img[alt='Nos Interventions'] {
        width: 90%;
    }
    #roster div img[alt='Nos Installations'] {
        width: 70%;
    }
   
    #roster div span{
        font-size:24px ;
        font-weight: 600;
        margin-top: 1rem;
    
        
    }
    }
@media screen and (width<=468px) {

    #Galsect1 {
        margin-top: 5rem;
        background-color: #003393;
        color: #fff;
    
    }
    #Galprev {
       
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0rem 1rem;
        
    }
    #Galeft {
        display: flex;
        flex-direction: column;
        margin-top: 5rem;
        margin-bottom: 2rem;
        width: 100%;
       
    }
    #Galeft h1 {
        font-size: 48px;
        line-height: 57px;
        font-weight:700;
        margin-bottom: 2rem;
    }
    #Nos {
        display: inline-block;
        font-size: 36px;
        line-height: 42px;
        font-weight: 100;
       
    
    }
    #Garight {
        width: 100%;
        position: relative;
        align-self: flex-start;
        display: contents;
    }
    #Garight img {
       width: 100%;
        top: 0;
    }
    #Galsect2 {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }
    #btngals2 {
        background-color: #003393;
        padding: 2rem 1rem;
    }
    #roster {
        margin-top: 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        justify-self: center;
        margin: 2rem 1rem;
    }
    #roster div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 4rem;
        cursor: pointer;

        
    }
    #roster div img[alt='Nos équipes'] {
        width: 70%;
    }
    #roster div img[alt='Nos Interventions'] {
        width: 90%;
    }
    #roster div img[alt='Nos Installations'] {
        width: 70%;
    }
   
    #roster div span{
        font-size:24px ;
        font-weight: 600;
        margin-top: 1rem;
    
        
    }
    }