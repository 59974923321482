/* Slideshow */

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    border-radius: 9.1rem;
  }
 
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }
  
  .slide {
    display: inline-block;
    width: 100%;

  }
  
  /* Boutons */
  
  .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 5px 0px;
    background-color: #c4c4c4;
  }
  
  .slideshowDot.active {
    background-color: #003393;
  }

  @media screen  and (width<=468px){
    .slideshow {
      margin: 1rem;
      overflow: hidden;
      border-radius:3rem;
    }
    .slideshowDot {
      display: inline-block;
      height: 1rem;
      width:1rem;
      border-radius: 50%;
      cursor: pointer;
      margin: 15px 5px 0px;
      background-color: #c4c4c4;
    }
    .slideshowSlider {
      white-space: nowrap;
      transition: ease 1000ms;
      border-radius:4rem;
  
    }
  
    
  }