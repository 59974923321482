@media screen and (width>=920px) {
  #callcommision {
    display: flex;
    gap: 1rem;
    padding-bottom: 4rem;
  } 

#locationbg {
    width: 100%;
    background-color: #003393;
 
}
#Location {
    margin-top: 25rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /*gap: 18rem;*/
    padding: 4rem 1rem;
    background-image: url(../../assets//parc.png);
    background-position-x: 100%;
    background-position-y: 35%;
    background-size: 75%;
    background-repeat: no-repeat;

}


 #textelocation {
    margin-bottom: 2rem;
    width: 60%;
    padding: 4rem 4rem 0rem 4rem;
 }

#textelocation h1 {
    margin-bottom: 4rem;
    font-size: 2.3rem;
    line-height: 2.5rem;
    color: #fff;
}
#textelocation p {
    margin-bottom: 5rem;
    color: #fff;
    width: 60%;

}
#imagevoiture {
    width: 50%;
}
#imagevoiture img {
    width: 100%;
}

#Service {
   
    margin-top: 110px;
   
}
.Centercontent {
    width: 1152px;
    margin-left:auto ;
    margin-right:auto ;


}

#Servicesection1slide{
    width:1152px ;
   margin-right: auto;
   margin-left: auto;


}

#slideshow {
    overflow: hidden;
   border-radius: 9.1rem;

}



#Servicesection1 {
    display: flex;
    justify-content: center;
    margin-top: 60px;
   
}

#Servicesection1item1 {
    position: relative;
    width: 50%;
}

#Servicesection1item1 img {
    /*width:100%;*/
    width: 683px;
    height: 739px;
    position: absolute;
    top: 0;
    right: -7rem;

}

#Servicesection1item2 {
    display: block;
    width: 50%;
    padding-bottom: 50px;
}

#Servicesection1item2 h1 {
    font-size: 40px;
    line-height: 120%;
}


#Servicesection1item2 #barresurligne {
    width: 7em;
    height: 0.3em;
    background-color: #003393;
    display: block;
    margin-top: 1em;
    margin-bottom:1em ;
}

#Servicesection1item2 p {
    font-size: 1rem;
    line-height: 128%;
    /*width: 336px;*/
    width: 70%;
}

#Servicesection1item2div {
    position: relative;
    width: 100%;
}

#Servicesection1item2div div{
    position: absolute;
    width: max-content;
    bottom: -80px;

}

#Servicesection2 {
    width: 100%;
    /*height: 350px;*/
    background-color: #003393;
    display: block;
    padding-top: 50px;
 
}

#Servicesection3 {
    background-color: #003393;
   
}

#Servicesection3flex {
    display: flex;
    align-items: center;
     padding: 0rem 1rem;
}

#Servicesection3flex div {
    font-size: 2.3rem;
    line-height: 112%;
    font-weight: 700;
    color: #fff;
    width: 50%;
   
    
}


#Servicesection4 {
    background-color: #003393;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;
    

}

#Servicesection4 div span#ws{
   color: #fff;
   display: block;
   margin-left: 5rem;
   font-size: 36px;
   font-weight: 100;
   line-height: 108.3%;
   padding-bottom: 1rem;

}

#Servicesection4 div span#ctn{
    color: red;
    display: block;
    font-size: 40px;
    margin-left: 5rem;
    padding-bottom: 1rem;
 }

#Servicesection4div {
    width: 1152px;
    margin: 2rem auto;

}
 #Servicesection4div img {
    /*width: 1152px;*/
    width: 100%;
    padding: 0rem 2rem;
}

#Servicesection5 {
   display: flex;
   justify-content: center;
   margin-top: 1rem;
   font-size: 36px;
   color:#000;
   font-weight: 700;
   line-height: 115%;

}
#Servicesection5 div {
    margin-top: 90px;
 
 }
 #Servicesection5 div p{
    margin-bottom: 20px;
 
 }

#Servicesection6 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    font-size: 36px;
   color:#000;
   font-weight: 600;
   line-height: 115%;

}
#Servicesection5 div p,#Servicesection6 div p {
    padding-bottom: 2rem;
    padding-left: 3rem;
    padding-right: 1rem; 


}

#Servicesection5 div,#Servicesection6 div,#Servicesection5 img,#Servicesection6 img {
   width: 50%;

}

#Servicesection7 {
    display: flex;
   /* background-image: url(../../assets/bg-commissionaire.png);
    background-size:auto;
    background-repeat: no-repeat;
    background-position: bottom;*/
   
    overflow: hidden;
    padding: 0rem 1rem 0rem 1rem;
    

}
#bg_com {
    position: absolute;
    bottom: -3%;
    right: -70%;
    width: 1258px; 
    height: 1258px;
    background-image: url(../../assets/bg-commissionaire.png);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: bottom;
    
}

#Servicesection7text {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10rem;

}
#Servicesection7text span{
    font-size: 32px;
    line-height: 115%;
    margin-bottom: 30px;

}
#Servicesection7text p{
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 30px;

}
#Servicesection7text img {
    width: 30rem;

}
#Servicesection7commissionaire {
    width: 50%;
    position: relative;


}
#Servicesection7commissionaire img {
  
     /*width: 45rem;
    height: 45rem;
    width: 681px;
    height: 681px;*/
    position: absolute;
    top: 120px;
    width: 100%;
    

    
}

}

@media  screen and (width<920px) and (width>468px){
    .Centercontent{
        width: 100%;
    }
    #callcommision {
        display: flex;
        gap: 1rem;
      } 

      #locationbg {
        width: 100%;
        background-color: #003393;
     
    }
    #Location {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 2rem 1rem;
        background-image: url(../../assets//parc.png);
        background-position-x: 92%;
        background-position-y: 35%;
        background-size: 50%;
        background-repeat: no-repeat;
    
    }
    
    
     #textelocation {
        margin-bottom: 2rem;
        width: 80%;
        padding: 4rem 4rem 0rem 2rem;
        display:flex;
        flex-direction: column;
     }
    
   
    #imagevoiture {
        width: 50%;
    }
    #imagevoiture img {
        width: 100%;
    }
    
    
     div[id='buttonlocation'] {
        align-self:flex-end;
        margin-right: 4rem;
     }
    #textelocation p {
        margin-bottom: 2rem;
        color: #fff;
        width: 70%;
    
    }
    #textelocation h1 {
        margin-bottom: 1rem;
        font-size: 2.3rem;
        line-height: 2.5rem;
        color: #fff;
    }
    
        #Service {
       
            margin-top: 110px;
            width: 100%;
           
        }
        
        #Servicesection1 {
            display: flex;
            gap: 2rem;
            justify-content: center;
            align-items: center;
            margin: 1rem 1rem;
           
        }
        
        #Servicesection1item1 {
            width: 50%;
            padding: 0.5rem;
            order: 2;
        }
        
        #Servicesection1item1 img {
            width:100%;
        }
        
        
        #Servicesection1item2 {
            width: 50%;
            margin: 0rem 1rem;
            padding-bottom: 2rem;
            order: 1;
    
        }
        
        #Servicesection1item2 h1 {
            font-size: 2rem;
            font-weight: 700;
            line-height: 120%;
        }
        #Servicesection1item2 h1 br {
            display: contents;
        }
        
        
        #Servicesection1item2 #barresurligne {
            width: 7em;
            height: 0.3em;
            background-color: #003393;
            display: block;
            margin-top: 1em;
            margin-bottom:1em ;
            align-self: flex-start;
        }
        
        #Servicesection1item2 p {
            font-size: 14px;
            line-height: 128%;
            width: 100%;
            padding-bottom: 2rem;
        }
        
        #Servicesection1item2div {
        
            align-self: center;
            order: 3;
            margin: 2rem 1rem;
        }
        
        #Servicesection1item2div div{
            width: max-content;
           
        }
        
        
        #Servicesection3 {
            background-color: #003393;    
        }
        
        #Servicesection3flex {
            display: flex;
            align-items: center;
            width: 100%;
            padding:2rem 1rem;
        }
        
        #Servicesection3flex div {
            font-size: 1.8rem;
            text-align: center;
            line-height: 112%;
            font-weight: 700;
            color: #fff;
            width: 100%;
            padding-bottom: 2rem;
        }
        
        #Servicesection3flex div img{
            width: 100%;
        }
        
        #Servicesection4 {
            background-color: #003393;
            font-weight: 700;
            display: flex;
            flex-direction: column;
            width: 100%;
            padding:2rem 1rem;;
        
        }
        
        #Servicesection4 div span#ws{
           color: #fff;
           display: block;
           margin-left: 2rem;
           font-size: 1.5rem;
           font-weight: 100;
           line-height: 108.3%;
           padding-bottom: 1rem;
        
        }
        
        #Servicesection4 div span#ctn{
            color: red;
            display: block;
            font-size: 1.5rem;
            margin-left: 2rem;
            padding-bottom: 1rem;
         }
        
         #Servicesection4 img {
            width: 100%;
        }
        
        #Servicesection5 {
           display: flex;
           justify-content: center;
           align-items: center;
           margin-top: 1rem;
           font-size: 1.5rem;
           color:#000;
           font-weight: 700;
           line-height: 115%;
           width: 100%;
           padding: 0rem 1rem;
        
        }
        #Servicesection5 div {
            margin-top: 2rem;
            margin-right: 2rem;
            
         }
         
         #Servicesection5 div p{
            margin-bottom: 1rem;
         
         }
        
        #Servicesection6 {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;
            font-size: 1.5rem;
           color:#000;
           font-weight: 600;
           line-height: 115%;
           width: 100%;
           padding: 0rem 1rem;
        
        }
        #Servicesection6 div {
            margin-left: 2rem;
        }
        #Servicesection6 img {
            width: 100%;
            order: -1;
        }
        #Servicesection5 div p br,#Servicesection6 div p br {
         display: contents;   
        }
        
        #Servicesection5 div,#Servicesection6 div,#Servicesection5 img,#Servicesection6 img {
           width:50%;
        
        }
        #Servicesection5 div,#Servicesection6 div {
            padding:1rem 0rem;
        }
        #Servicesection7 {
            display: flex;
            width: 100%;
            overflow: hidden;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-top: 2rem;            
            
        }
        
        #Servicesection7text {
            width: 50%;
            margin: 2rem 1rem;
        }
       
        #Servicesection7text span{
            font-size: 1.5rem;
            line-height: 115%;
            margin-bottom: 2rem;
            
        
        }
        #Servicesection7text img {
            width: 100%;
        }
        #Servicesection7text p{
            font-size: 1.2rem;
            line-height: 120%;
            margin-bottom: 2rem;
            padding: 0rem 1rem;
        
        }
       
        #Servicesection7commissionaire {
            width: 50%;
            position: relative;
            align-self: flex-end;
            
        
        
        }
        #Servicesection7commissionaire img {
            width: 100%;
        }
        
        #bg_com {
    
            position: absolute;
            bottom: 0;
            right: 0;
            width:100%;
            height: 30rem;
            background-image: url(../../assets/bg-commissionaire.png);
            background-size:cover;
            background-repeat: no-repeat;
            background-position: bottom;
            z-index: -1;
            
        }
       
        /*Order*/
        .ord1 {
            order: -4;
            
        }
        .ord2 {
            order: -3;
    
        }
        .ord3 {
            order: -2;
        }
        .ord4 {
    
            order: 2;
        }
        .buttondevis {
            padding: 1rem 2rem;
        }
    
 }
    

@media screen and (width<=468px) {
#callcommision {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  } 
  #locationbg {
    width: 100%;
    background-color: #003393;
    

}
#Location {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*gap: 18rem;*/
    padding: 4rem 1rem;
    
}

 #textelocation {
    margin-bottom: 2rem;
    width: 100%;
    padding: 0rem 1rem;
    display: flex;
    flex-direction: column;
    
 }
 div[id='buttonlocation'] {
    align-self: center;
 }
#textelocation p {
    margin-bottom: 2rem;
    color: #fff;

}
#textelocation h1 {
    margin-bottom: 1rem;
    font-size: 2.3rem;
    line-height: 2.5rem;
    color: #fff;
}
#imagevoiture {
    width: 100%;
    padding: 0rem 1rem;
}
#imagevoiture img {
    width: 100%;
}
    #Service {
   
        margin-top: 110px;
        width: 100%;
       
    }
    
    #Servicesection1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1rem 1rem;
       
    }
    
    #Servicesection1item1 {
        width: 100%;
        padding: 0.5rem;
        order: 2;
    }
    
    #Servicesection1item1 img {
        width:100%;
    }
    
    
    #Servicesection1item2 {
        width: 100%;
        margin: 0rem 1rem;
        padding-bottom: 2rem;
        order: 1;
        display: contents;
    }
    
    #Servicesection1item2 h1 {
        font-size: 2rem;
        font-weight: 700;
        line-height: 120%;
    }
    #Servicesection1item2 h1 br {
        display: contents;
    }
    
    
    #Servicesection1item2 #barresurligne {
        width: 7em;
        height: 0.3em;
        background-color: #003393;
        display: block;
        margin-top: 1em;
        margin-bottom:1em ;
        align-self: flex-start;
    }
    
    #Servicesection1item2 p {
        font-size: 14px;
        line-height: 128%;
        width: 100%;
        padding-bottom: 2rem;
    }
    
    #Servicesection1item2div {
    
        align-self: center;
        order: 3;
        margin: 2rem 1rem;
    }
    
    #Servicesection1item2div div{
        width: max-content;
       
    }
    
    #Servicesection2 {
        display: none;
     
    }
    
    #Servicesection3 {
        background-color: #003393;    
    }
    
    #Servicesection3flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding:2rem 1rem;
    }
    
    #Servicesection3flex div {
        font-size: 1.8rem;
        text-align: center;
        line-height: 112%;
        font-weight: 700;
        color: #fff;
        width: 100%;
        padding-bottom: 2rem;
    }
    
    #Servicesection3flex div img{
        width: 100%;
    }
    
    #Servicesection4 {
        background-color: #003393;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding:2rem 1rem;;
    
    }
    
    #Servicesection4 div span#ws{
       color: #fff;
       display: block;
       margin-left: 2rem;
       font-size: 1.5rem;
       font-weight: 100;
       line-height: 108.3%;
       padding-bottom: 1rem;
    
    }
    
    #Servicesection4 div span#ctn{
        color: red;
        display: block;
        font-size: 1.5rem;
        margin-left: 2rem;
        padding-bottom: 1rem;
     }
    
     #Servicesection4 img {
        width: 100%;
    }
    
    #Servicesection5 {
       display: flex;
       flex-direction: column;
       justify-content: center;
       margin-top: 1rem;
       font-size: 1.5rem;
       color:#000;
       font-weight: 700;
       line-height: 115%;
       width: 100%;
       padding: 0rem 1rem;
    
    }
    #Servicesection5 div {
        margin-top: 2rem;
        
     }
     #Servicesection5 img {
        width: 100%;
     }
     #Servicesection5 div p{
        margin-bottom: 1rem;
     
     }
    
    #Servicesection6 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        font-size: 1.5rem;
       color:#000;
       font-weight: 600;
       line-height: 115%;
       width: 100%;
       padding: 0rem 1rem;
    
    }
    #Servicesection6 img {
        width: 100%;
        order: -1;
    }
    #Servicesection5 div p br,#Servicesection6 div p br {
     display: contents;   
    }
    
    #Servicesection5 div,#Servicesection6 div,#Servicesection5 img,#Servicesection6 img {
       width: 100%;
    
    }
    #Servicesection5 div,#Servicesection6 div {
        padding:1rem 0rem;
    }
    #Servicesection7 {
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow: hidden;

        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        
        
    }
    
    #Servicesection7text {
    
        display: contents;
    
    }
   
    #Servicesection7text span{
        font-size: 1.5rem;
        line-height: 115%;
        margin-bottom: 2rem;
        
    
    }
    #Servicesection7text img {
        width: 100%;
    }
    #Servicesection7text p{
        font-size: 1.2rem;
        line-height: 120%;
        margin-bottom: 2rem;
        padding: 0rem 1rem;
    
    }
   
    #Servicesection7commissionaire {
        width: 100%;
        position: relative;
        margin-bottom: 1rem;
        
    
    
    }
    #Servicesection7commissionaire img {
        width: 100%;
    }
    
    #bg_com {

        position: absolute;
        bottom: 0;
        right: 0;
        width:100%;
        height: 30rem;
        background-image: url(../../assets/bg-commissionaire.png);
        background-size:cover;
        background-repeat: no-repeat;
        background-position: bottom;
        z-index: -1;
        
    }
   
    /*Order*/
    .ord1 {
        order: -4;
        
    }
    .ord2 {
        order: -3;

    }
    .ord3 {
        order: -2;
    }
    .ord4 {

        order: -1;
    }

}
