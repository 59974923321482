#divformulaire_mini_devis {
    background-color: #003393;
    width: 350px;
    color: #fff;
    border-radius: 1rem;
}
#divformulaire_mini_devis h1 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 20px;
}
#formulaire_mini_devis {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 50px;
    margin-right: 47px;
   
}
.inputsmini{
    padding:20px;
    margin-top: 20px;
    border-radius: 20px;
    border: #fff 1px solid;
   color: #fff;
    width: 250px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    
    
}

.erreur{
    padding:20px;
    margin-top: 20px;
    border-radius: 20px;
    border: #f00 1px solid;
    box-shadow:0px 0px 1vw #f00;
    color: #f00;
    width: 250px;
    height: 50px;
    background-color: #fcdddd;
    outline: none;
    transition: 1s;
    
    
}
#mini_msg_devis {
    padding:20px;
    margin-top: 20px;
    border-radius: 20px;
    width: 250px;
    height: 100px;
    text-align: start;
    resize: none;
    outline: none;
    font-family: poppins;
    
}
#checkboxdivmini {
    display: flex;
    font-size: 14px;
    margin-top: 50px;
    margin-bottom: 20px;
    
}
#checkboxfmini {
    
    width: 15px;
    height: 15px;
    margin-right: 20px;

}
#abobtnmini {
    width: 100%;
    border-radius: 10px;
    height: fit-content;
    padding: 1rem 2rem;
    text-align: center;
    margin-bottom: 5rem;

}
#abobtnmini:hover {
    border-radius: 15px;
    padding: 1rem 2rem;
    text-align: center;
    margin-bottom: 5rem;
    background-color: #ff0000;
    transition: 0.2s;
    font-size: 1.2rem;

}

textarea[id='mini_msg-devis'] {
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
    border: #fff 1px solid;
}
#mini_msg_devis [class=erreur] {
     color:#f00;
     border:#f00 1px solid
}

div[id='divformulaire_mini_devis'] input::placeholder,div[id='divformulaire_mini_devis'] textarea::placeholder{
    color: #fff;
    font-family: poppins;
  }
input[class='erreur']{
    color: #f00;
  }