#articlelink{
    
    text-decoration: none;
}
.contenaire {
    width: 15rem;
    height: 20rem;
    background-color: aliceblue;
    border-radius: 2rem;
    color: black;
    padding-bottom: 1rem;
    margin-right: 3.3rem;
    

}

.contenaire img {
    width: 15rem;
    overflow: hidden;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;


}

.contenaire .paragraph {
    margin-right: 1.2rem;
    margin-left: 1.2rem;
    color: black;
    font-size: 0.7rem;
    font-weight: 500;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /*height: 100px;*/
    text-overflow: ellipsis;
}

.contenaire a {
    text-decoration: none;
}


.contenaire span#lire {

    margin-left: 1.2rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.7rem;
    padding-right: 0.5rem;

}

.contenaire span#arrow {
    font-size: 2rem;
    vertical-align:middle;
    
}

.contenaire span, .contenaire span a {
    color: black;
    font-size: 0.8rem;
    font-weight: 700;
    
}

.contenaire span#lire:hover {
   background-color: #e5e9ef;
   color: #17235c;
   
}
.contenaire span:hover {
    font-size: 0.9rem;
    vertical-align:middle;
    transition: 0.1s;
    
}



