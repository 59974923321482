#Artillustrimg {
    width: 100%;
}
.visuel {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap :1rem;

    flex-wrap: wrap;
    margin: 0 auto;

}

.visuel img {
    width: 100%;
}

.visuel .vitem1 {
    width: 333px ;

}

.visuel .vitem2 {
    width: 333px ;
    
}

.visuel .vitem5 {
    width: 444px;
    
}

.visuel .vitem3 {
    width: 562px ;
    
}

.visuel .vitem4 {
    width: 562px;
    
}




@media screen and (width>=920px) { 
      
    #Articles {
        display: flex;
        align-items: flex-start;
        width: 1152px;
        margin-top: 8rem;
        margin-left: auto;
        margin-right: auto;
    }
    #Articles_content {
        margin-right: 2rem;
        margin-top:0px ;
        margin-bottom: 5rem;
    }
    #Artillustr {
        width: 100%;
        display: flex;
        flex-direction: column;
       
    }
    #Artillustrimg {
        height: 552px;
        width: 773px;
       border-radius: 50px 50px 0px 0px;
       background-position: top;

    }
    #Aref {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    #date {
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
    }
    #rs {
    
        display: flex;
        justify-content: space-between;

    }
    #rs img{
        width: 107px;
        height: 25px;
        margin-left: 10px;
    

    }
    
    #Articles_content h1 {
        font-size: 24px;
        margin-bottom: 2.5rem;      
        font-weight: 700;
        line-height: 32px;
        text-align: left;

    }
    
    #Articles_content div p {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 1rem;
        width: 90%;
    }

    #Interventions {
   
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    #Interventions_center {
        width: 1152px;
        margin-left: auto;
        margin-right: auto;
    }

    .Akati {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Akati h1 {
        font-size: 2.5rem;
        line-height: 0.7rem;
        letter-spacing: 5%;
        padding-bottom: 1.7rem;
        color: #000;
        
     }
     .Akati p {
    
        color: #000;
       padding-bottom: 4rem;
       font-weight: 700;
       font-size :20px;
       line-height: 20px;
    
     }
    
    .flexrow {
        display: flex;
        width: 100%;
        justify-content:center;
        
    }
    #art1,#art2,#art3,#art4 {
      box-shadow: 8px 8px 10px #a1a1a1;
      
    }

    .visuel {
    
        width: 1142px;
        margin: 0 auto;
    
    }
    
  }
@media screen and (width<920px) and (width>468px) { 
      
    #Articles {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 1152px;
        margin-top: 8rem;
        margin-left: auto;
        margin-right: auto;
    
    }
    #Articles_content {
        margin-right: 2rem;
        margin-top:0px ;
        margin-bottom: 5rem;
    }
    #Artillustr {
        width: 100%;
        display: flex;
        flex-direction: column;
       
    }
    #Artillustrimg {
        height: 552px;
        width: 773px;
       border-radius: 50px 50px 0px 0px;
       background-position: top;

    }
    #Aref {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    #date {
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
    }
    #rs {
    
        display: flex;
        justify-content: space-between;

    }
    #rs img{
        width: 107px;
        height: 25px;
        margin-left: 10px;
    

    }
    
    #Articles_content h1 {
        font-size: 24px;
        margin-bottom: 2.5rem;      
        font-weight: 700;
        line-height: 32px;
        text-align: left;

    }
    
    #Articles_content div p {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 1rem;
        width: 90%;
    }

    #Interventions {
   
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    #Interventions_center {
        width: 1152px;
        margin-left: auto;
        margin-right: auto;
    }

    .Akati {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Akati h1 {
        font-size: 2.5rem;
        line-height: 0.7rem;
        letter-spacing: 5%;
        padding-bottom: 1.7rem;
        color: #000;
        
     }
     .Akati p {
    
        color: #000;
       padding-bottom: 4rem;
       font-weight: 700;
       font-size :20px;
       line-height: 20px;
    
     }
    
    .flexrow {
        display: flex;
        width: 100%;
        justify-content:center;
        
    }
    #art1,#art2,#art3 {
      box-shadow: 8px 8px 10px #a1a1a1;
      
    }

    .visuel {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap :1rem;
    
        flex-wrap: wrap;
        margin: 0 auto;
    
    }

    
  }   
@media screen and (width<=468px) { 
      
    #Articles {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 7rem;
       
    }
    #Articles_content {
        margin-bottom: 5rem;
        padding:0rem 1rem;
    }

    #Artillustr {
        width: 100%;
        display: flex;
        flex-direction: column;
       
    }
    #Artillustrimg {
       border-radius: 30px 30px 0px 0px;
       background-position: top;
    }
    #Aref {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    #date {
        font-weight: 600;
        font-size: 0.5rem;
        line-height: 100%;
        text-align: center;
    }
    #rs {
    
        display: flex;
        justify-content: space-between;

    }
    #rs img{

        margin-left: 0.5rem;

    }
    
    #Articles_content h1 {
        font-size: 1.8rem;
        margin-bottom: 2rem;      
        font-weight: 700;
        line-height: 1.8rem;

    }
    
    #Articles_content div p {
        font-size: 1rem;
        line-height: 1rem;
        margin-bottom: 2rem;
        
    }

    #Interventions {
   
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    #Interventions_center {
        width: 100%;
    }

    .Akati {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .Akati h1 {
        font-size: 2.5rem;
        letter-spacing: 5%;
        padding-bottom: 1.7rem;
        color: #000;
        padding: 1rem 1rem;
        
     }
     .Akati p {
    
        color: #000;
       padding-bottom: 4rem;
       font-weight: 700;
       font-size :1.2rem;
       line-height: 1.2rem;
       padding: 0rem 1rem 2rem 1rem;
    
     }
    
    .flexrow {
        display: flex;
        width: 100%;
        justify-content:flex-start;
        padding-bottom: 2rem;
        
    }
    #art1,#art2,#art3 {
      box-shadow: 8px 8px 10px #a1a1a1;
      
    }
    #Articles div[id='divformulaire_mini_devis'] {
        margin: 0rem 1rem;
    }

    .visuel {
        display: flex;
        justify-content: center;
        align-items: center;
        gap :1rem;
    
        flex-wrap: wrap;
    
       margin: 2rem 1rem;
    
    }
    
  }
