#Aboutsection1 #nosreseaux{
 cursor: pointer;
 
}
#nosreseaux img:hover {
    scale: 1.2;
    transition: all 0.2s ease;
}

@media screen and (width>=920px) {
#dame {
    display: none;
}
#About {
    
        margin-top: 150px;
    
    }
.Centercontent {
    width: 1152px;
    margin-left:auto ;
    margin-right:auto ;}
    
#Aboutsection1 {
    background-image: url(../../assets/Martine.png);
    background-position:top;
    background-repeat: no-repeat;
    height: 660px;/**/
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 1200px;
    margin-left:auto ;
    margin-right:auto ;

}
#Aboutsection1 #sect1textg {
    
    display:block;
    width: 310px;
}
#Aboutsection1 #sect1textg p{
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 2rem;


}
#Aboutsection1 #sect1textg span{
    text-align: right;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 2rem;

}

#ladame {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    z-index: -1;
}
.dame {
    position: absolute;
    width: 560px;
    height: 747px;
}

#Aboutsection1 #sect1textg span,#Aboutsection1 #sect1textg div {
    display: block;
    text-align: end;
    margin-top: 0.2em;
    font-weight: 700;
   
}

#Aboutsection1 #nosreseaux img {
    width: 3rem ;
    height: 3rem;
    margin: 0.3rem;
    
}

#accroche {
    color :#003393;
    margin-top: 25em;
}

#accroche h1{

    font-size: 1.9rem;
    font-weight: 700;
}
#accroche h1 span{

    font-size: 1.3rem;
    font-weight: 700;
}
#accroche span{
    font-size: 1.5rem;
    font-weight: 100}
.chef {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.subordonnee {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
}


#Aboutsection2 {
    background-color: #003393;
    color: #fff;
    padding-top: 3rem;
    padding-bottom: 3rem;

}
#Aboutsection2 h1 {
    font-size: 3rem;
    padding-bottom: 0.2rem;
}

#Aboutsection2 p {
    font-size: 1.5rem;
    padding-bottom: 2rem;
    font-weight: 400;
    line-height: 123%;
   /* text-align: center;*/
   text-align: justify;
}
#Aboutsection2 h1,#Aboutsection2 h2,#Aboutsection2 p {
    text-align: center;
}

#Aboutsection2plus {
    width: 1152px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

#Aboutsection2plus h1 {
    font-size: 3rem;
    text-align: center;
    color: #003393;
    padding-bottom: 2rem;
}
#Aboutsection2plus div{
    background-color: #bebebe;
    padding:2rem 2rem;


}
#Aboutsection2plus p {
    font-size: 1.5rem;
    padding-bottom: 2rem;
}

#Aboutsection3 {
    text-align: center;
    padding-top: 2rem;
}

#Aboutsection3 h1{
    color: #003393;
    padding-bottom: 0.3rem;
}
#Aboutsection3 h2 {
    color: #003393;
    font-size: 1.2rem;
    font-weight: 100;
    padding-bottom: 1rem;

}

#Aboutsection3 p {
    font-size: 1.2rem;
    padding-bottom: 2rem;
    

}
#Aboutsection4 {
    margin-bottom: 5rem;
}

}
@media  screen and (width<920px) and (width>468px) {
    #About {
        
        margin-top: 6rem;
    
    }
    
    #Aboutsection1 {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 0rem 1rem 0rem 1rem;
    
    }
    #Aboutsection1 #sect1textg {
        
        display:flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-bottom: 2rem;
        padding: 0.5rem;
        background-color: #003393;
        border-radius: 2rem;
        color: #fff;
    }
    #Aboutsection1 #sect1textg p{

        font-size: 1.2rem;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 2rem;
    }
    #Aboutsection1 #sect1textg span{
        font-size: 1.3rem;
        line-height: 30px;
        margin-bottom: 0.3rem;
        order: -1;
    
    }
    #Aboutsection1 #nosreseaux{
        order: -1;
    }
    
    #Aboutsection1 #sect1textg span,#Aboutsection1 #sect1textg div {
        display: block;
        margin-top: 0.2em;
        font-weight: 700;
    }
    
    #Aboutsection1 #nosreseaux img {
        width: 2rem ;
        height: 2rem;
        margin: 0.3rem;
        
    }
    
    #accroche {
        color :#003393;
        text-align: center;
        order: -1;
    }
    
    #accroche h1{
    
        font-size: 30px;
        font-weight: 700;
    }
    
    #accroche span{
        font-size: 1.5rem;
        font-weight: 100
    }
    #dame {
        display: flex;
        order: -1;
    }
    #dame img {
        width: 100%;
    }
    .chef {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .subordonnee {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    #Aboutsection2plus h1 {
        font-size: 2rem;
        text-align: center;
        color: #003393;
        padding: 1rem 0rem;
    }
    #Aboutsection2plus div{

        background-color: #d7d7d7;
        padding:2rem 1rem;
        margin: 2rem 2rem;
    
    
    }
    #Aboutsection2plus p {
        font-size: 1rem;
        padding-bottom: 2rem;

    }

    
    
    #Aboutsection2 {
        background-color: #003393;
        color: #fff;
        padding: 3rem 1rem;
    
    }
    #Aboutsection2 h1 {
        font-size: 2.5rem;
        padding-bottom: 1rem;
    }
    
    #Aboutsection2 p {
        font-size: 1.2rem;
        padding-bottom: 2rem;
        font-weight: 400;
        line-height: 123%;
        text-align: center;
    }
    #Aboutsection2 h1,#Aboutsection2 h2,#Aboutsection2 p {
        text-align: center;
    }
    
    #Aboutsection3 {
        text-align: center;
        padding-top: 2rem;
    }
    
    #Aboutsection3 h1{
        color: #003393;
        padding-bottom: 0.3rem;
    }
    #Aboutsection3 h2 {
        color: #003393;
        font-size: 1.2rem;
        font-weight: 100;
        padding-bottom: 1rem;
    
    }
    
    #Aboutsection3 p {
        font-size: 1.2rem;
        padding-bottom: 2rem;
        
    
    }
    #Aboutsection4 {
        margin-bottom: 5rem;
    }
    
}
@media screen and (width<=468px) {
    #About {
        
        margin-top: 6rem;
    
    }
    
    #Aboutsection1 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 0rem 1rem 0rem 1rem;
    
    }
    #Aboutsection1 #sect1textg {
        
        display:flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-bottom: 2rem;
        padding: 0.5rem;
        background-color: #003393;
        border-radius: 2rem;
        color: #fff;
    }
    #Aboutsection1 #sect1textg p{

        font-size: 1.2rem;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 2rem;
    }
    #Aboutsection1 #sect1textg span{
        font-size: 1.3rem;
        line-height: 30px;
        margin-bottom: 0.3rem;
        order: -1;
    
    }
    #Aboutsection1 #nosreseaux{
        order: -1;
        
    }
    
    #Aboutsection1 #sect1textg span,#Aboutsection1 #sect1textg div {
        display: block;
        margin-top: 0.2em;
        font-weight: 700;
    }
    
    #Aboutsection1 #nosreseaux img {
        width: 2rem ;
        height: 2rem;
        margin: 0.3rem;
        
    }
    
    #accroche {
        color :#003393;
        text-align: center;
        order: -1;
    }
    
    #accroche h1{
    
        font-size: 30px;
        font-weight: 700;
    }
    
    #accroche span{
        font-size: 1.5rem;
        font-weight: 100
    }
    #dame {
        display: flex;
        order: -1;
    }
    #dame img {
        width: 100%;
    }
    .chef {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .subordonnee {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    #Aboutsection2plus h1 {
        font-size: 2rem;
        text-align: center;
        color: #003393;
        padding: 1rem 0rem;
    }
    #Aboutsection2plus div{
       
        padding:2rem 1rem;
    
    
    }
    #Aboutsection2plus p {
        font-size: 1rem;
        padding-bottom: 2rem;
    }
    
    
    
    #Aboutsection2 {
        background-color: #003393;
        color: #fff;
        padding: 3rem 1rem;
    
    }
    #Aboutsection2 h1 {
        font-size: 2.5rem;
        padding-bottom: 1rem;
    }
    
    #Aboutsection2 p {
        font-size: 1.2rem;
        padding-bottom: 2rem;
        font-weight: 400;
        line-height: 123%;
        text-align: center;
    }
    #Aboutsection2 h1,#Aboutsection2 h2,#Aboutsection2 p {
        text-align: center;
    }
    
    #Aboutsection3 {
        text-align: center;
        padding-top: 2rem;
    }
    
    #Aboutsection3 h1{
        color: #003393;
        padding-bottom: 0.3rem;
    }
    #Aboutsection3 h2 {
        color: #003393;
        font-size: 1.2rem;
        font-weight: 100;
        padding-bottom: 1rem;
    
    }
    
    #Aboutsection3 p {
        font-size: 1.2rem;
        padding-bottom: 2rem;
        
    
    }
    #Aboutsection4 {
        margin-bottom: 5rem;
    }
    
}


