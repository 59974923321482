@media screen and (width>=920px) {
.globalnav {
    background-color: #003393;
    color: #fff;
    position: fixed;
    top: 0;
    width: 100%;

}
.globalnav a {
    text-decoration: none;
    color: #fff;
}
#navcustom img {
    padding: 1rem 2rem;

}
#navcustom {
    display: flex;
    justify-content: space-between;
    color: #fff;
    width: 1152px;
    margin-left: auto;
    margin-right: auto;
}
#navcustom ul {
    display: flex;
    justify-content: center;
    list-style: none;
    
}
#navcustom ul li{
    
    border-right: 1px solid #fff;
    display: flex;
    align-items: center;
    
}
#navcustom ul li a{
    
    display: block;
    color:#fff;
    text-align: center;
    padding: 40px 45px;
    text-decoration: none;
}
#navcustom ul li:hover,#navcustom ul li:hover a{
    background-color: #fff;
    color: #003393;
    cursor: pointer;
    transition: 0.5s;
    
}
#anumb {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 2rem;
}
#numb {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

}
#numb a {
    border: 1px #fff solid;
    border-radius: 2rem;
    margin-bottom: 0.2rem;
    padding: 0.2rem 1rem;
}
#numb a:hover {
    color: #003393;
    background-color: #fff;
    transition: 0.3s;
}
#numbfirstchild {
    font-size: 0.9rem;
    font-weight: 900;
    line-height: 20px;
    

}
#numbsecondchild {
    font-size: 15px;
    font-weight: 500;
    
}
#mobilenav {
    display: none;
}
.pol {
    display: none;
}
}

@media  screen and (width<920px) and (width>468px)  {
    .globalnav {
        background-color: #003393;
        color: #fff;
        position: fixed;
        top: 0;
        width: 100%;
    
    }
    .globalnav a {
        text-decoration: none;
        color: #fff;
    }
    #navcustom img[alt='logoblanc'] {
        padding: 1rem 2rem;
    
    }
    #navcustom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        padding-right: 1rem;

    }

    #navul {
        display: none;
        justify-content: center;
        flex-direction: column;
        list-style: none;
        
    }
    #navcustom ul li{
        
        display: flex;
        align-items: center;
        width: 100%;
        
    }
    #navcustom ul li a{
        
        display: block;
        color:#fff;
        text-align: center;
        padding: 1rem;
        text-decoration: none;
        width: 100%;
      
    }
    #navcustom ul li:hover a{
        background-color: #fff;
        color: #003393;
        cursor: pointer;
        transition: 0.5s;
        
        
    }
    #anumb {
        display: none;
        justify-content: center;
        align-items: center;
        padding-right: 2rem;
    }
    #numb {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
    
    }
    #numbfirstchild {
        font-size: 24px;
        font-weight: 900;
        line-height: 20px;
    
    }
    #numbsecondchild {
        font-size: 15px;
        font-weight: 500;
        
    }
    .pol {
        top: 6.5rem;
    }
}
@media screen and (width<=468px) {
    .globalnav {
        background-color: #003393;
        color: #fff;
        position: fixed;
        top: 0;
        width: 100%;
    
    }
    .globalnav a {
        text-decoration: none;
        color: #fff;
    }
    #navcustom img[alt='logoblanc'] {
        padding: 1rem 2rem;
    
    }
    #navcustom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        padding-right: 1rem;

    }

    #navul {
        display: none;
        justify-content: center;
        flex-direction: column;
        list-style: none;
        
    }
    #navcustom ul li{
        
        display: flex;
        align-items: center;
        width: 100%;
        
    }
    #navcustom ul li a{
        
        display: block;
        color:#fff;
        text-align: center;
        padding: 1rem;
        text-decoration: none;
        width: 100%;
      
    }
    #navcustom ul li:hover a{
        background-color: #fff;
        color: #003393;
        cursor: pointer;
        transition: 0.5s;
        
        
    }
    #anumb {
        display: none;
        justify-content: center;
        align-items: center;
        padding-right: 2rem;
    }
    #numb {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
    
    }
    #numbfirstchild {
        font-size: 24px;
        font-weight: 900;
        line-height: 20px;
    
    }
    #numbsecondchild {
        font-size: 15px;
        font-weight: 500;
        
    }
    .pol {
        top: 6.5rem;
    }
}