#reseaux a {
    width: 100%;
}  

@media  screen and (width>=920px) {

    #footerstart {
        background-color: #003393;
    }
    #footerstart_center {
            width: 1152px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: space-between;
            padding-top: 60px;
            padding-bottom: 70px;
            background-color: #003393;
            color: #fff;
    }

    #societe {
        display: flex;
        flex-direction: column;
        width: 25%;        
        
    }
    #societe img {
            width: 77%;
            margin-right: 90px;
        }
    #societe p {
        
            font-size: 16px;
            font-weight:700;
            margin-top: 20px;
            margin-right: 43px;
        
        }
    #reseaux {
        display: flex;
        width: 70%;

    }

    #reseaux img{
        margin-top: 35px;
        margin-right: 15px;
        cursor: pointer;
       
    }

    #s9services {
            display: flex;
            flex-direction: column;
            width: 25%;
        }
    #s9services ul {
            display: inline-block;
            font-size: 14px;
            line-height: 80%;
            
        }
    #s9services ul li {
            padding-top: 10px;
            list-style: none;
            
        }
        
    #s9adresse {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 25%;
          
            
        }
    #services, #adresse,#mail, #ouverture, #newsletters {
            font-weight: 700;
            font-size: 16px;
            padding-bottom: 8px;
            padding-top: 8px;
     } 
    #ouverture {
        text-transform: uppercase;
    }
    #newsletters {
        margin-bottom: 14px;
    }
    .adresses {
        font-weight: 500;
        font-size: 16px;
        display: block;
        margin-bottom: 8px;
    }
   
    #s9adresse div,#lescontacts{
            font-size: 14px;  
        }
    #adresseBz,#adressePn,#addremail,#daysopned {
        margin-bottom: 12px;
       
    }

    #mail {
        display: block;
        margin-bottom: 1px;
    }
   
    
    #s9news {
            display: flex;
            flex-direction: column;
           
            width: 25%;
        }

    .inputs {
        border-radius: 14px;
        width: 100%;
        height: 64px;
        margin : 0 0 20px 0;
        padding-left: 30px;
        border-style: none;
        font-size: 16px;
        outline: none;
    
        

    }
    /*#Nom {

    }
    #adressemail {

    }*/
    #checkboxdiv {
        display: flex;
        align-items: flex-start;
        margin-top: 8px;
        margin-bottom:16px;
        font-size: 12px;
    }
    #checkboxfdiv {
        display: inline;
        margin-right: 12px;
        margin-top: 6px;
        
    }
    #checkboxf {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        clip-path: circle(52% at 50% 50%);
    }

    #abobtn {
        width: 80%;
        height:40px ;
        background-color: #ff0000;
        text-align: center;
        cursor: pointer;
        padding-top: 8px;
        padding-bottom: 20px;
        border-radius: 10px;
    }

    #footer {
        width: 1152px;
        margin-left: auto;
        margin-right: auto;

        }
    .footerbg {
        background-color:#01215D;
        /*border-top:   2px solid #EAF6FE;*/
        padding-top: 20px;
        padding-bottom: 20px;
    }

    #footer,#footer a {
        color: #EAF6FE;
        padding: 0.3rem;
        text-decoration: none;
    }

    #footer ul li {
        padding-right:4rem;
        padding-left: 0.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 0.7rem;
        width: 25%;
    }
    #footer ul li:not(:last-child) {
        
        border-right: 3px solid aliceblue;
    }
    #flist {
        list-style-type: none;
        display: flex;
    }

}

@media  screen and (width<920px) and (width>468px) {
   
    #footerstart_center {
    
            color: #fff;
            background-color: #003393;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
    }
    #societe {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        padding-left: 2rem; 
        padding-top: 2rem;
        padding-bottom: 4rem;
        
    }
    #societe img {
        width: 100%;
       
    }
    #societe p {
            
        font-size: 1rem;
        font-weight:700;
        margin-top:1rem;
        margin-right: 2rem;
    
    }
    #reseaux {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    
    }
    
    #reseaux img{
        padding-right: 0.5rem;
        cursor: pointer;
        width: 25%;
       
    }
    #s9services {
        display: flex;
        flex-direction: column;
        width: 25%;
        padding-left: 1rem;
        padding-top: 2rem;
        padding-bottom: 4rem;
    }
    #s9services ul {
        display: inline-block;
        font-size: 14px;
        line-height: 80%;
        
    }
    #s9services ul li {
        padding-top: 10px;
        list-style: none;
        
    }
    
    #s9adresse {
        display: flex;
        flex-direction: row;
        align-items: flex-start;   
        width: 50%;
        padding-top: 2rem;
        padding-left: 2rem;
        padding-right: 1rem;
      
        
    }
    
    .s9adresse1 {
        padding-right: 1rem;
    }
    
    #adresse{
        width: 100%;
    }
    #adresseBz {
        width:100%;
        order: 3;
    }
    #adressePn {
        width: 100%;
        order:4;
    }
    #addremail {
        width: 100%;
        order:1;
    
    }
    .sept{
        width: 100%;
        order: 5;
    }
    #ouverture {
    
        width: 100%;
       
    }
    #daysopened {
        width: 100%;
       
    }
    #services, #adresse,#mail, #ouverture, #newsletters {
        font-weight: 700;
        font-size: 16px;
        padding-bottom: 8px;
        padding-top: 8px;
    } 
    #ouverture {
    text-transform: uppercase;
    }
    #newsletters {
    margin-bottom: 1rem;
    
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
    
    }
    
    .adresses {
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
    }
    
    #s9adresse div,#lescontacts{
        font-size: 14px;  
    }
    #adresseBz,#adressePn,#addremail,#daysopned {
    margin-bottom: 12px;
    
    }
    
    #mail {
    display: block;
    margin-bottom: 1px;
    }
    
    
    #s9news{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #01215D;
      
    
    }
    
    .inputs {
    border-radius: 14px;
    width: 100%;
    height: 2.5rem;
    margin : 0 0 20px 0;
    padding-left: 30px;
    border-style: none;
    border: #fff 1px solid;
    color: #fff;
    font-size: 16px;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    
    }
    
    #s9news .inputs::placeholder {
     color: #898989;
    
    }
    
    #checkboxdiv {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
    margin-bottom:16px;
    font-size: 12px;
    }
    #checkboxfdiv {
    display: inline;
    margin-right: 12px;
    margin-top: 6px;
    
    }
    #checkboxf {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    /*clip-path: circle(52% at 50% 50%);*/
    
    }
    
    #abobtn {
    width: 100%;
    height:40px ;
    background-color: #ff0000;
    text-align: center;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 20px;
    border-radius: 10px;
    }
    
    #footer {
    width: 100%;
    }
    .footerbg {
    background-color:#01215D;
    padding: 0.5rem 0rem;
    
    }
    
    #footer,#footer a {
    color: #EAF6FE;
    padding: 0.3rem;
    text-decoration: none;
    }
    
    #footer ul li {
    padding-right:2rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.7rem;
    width: 100%;
    }

    #footer ul li:not(:last-child) {
        
        border-right: 3px solid aliceblue;
    }
    
    #flist {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    
    }
    
     }
 
@media screen and (width<=468px) {
#footerstart {
        background-color: #003393;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
       
}
     
#footerstart_center {

        display: contents;
        color: #fff;
}
#societe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;    
    padding-right: 0rem;   
    padding-left: 2rem; 
    padding-top: 2rem;
    
}
#societe img {
    width: 100%;
   
}
#societe p {
        
    font-size: 1rem;
    font-weight:700;
    margin-top:1rem;
    margin-right: 2rem;

}
#reseaux {
display: flex;
width: 100%;
margin-top: 1rem;

}

#reseaux img{
    padding-right: 0.5rem;
    cursor: pointer;
    width: 25%;
   
}
#s9services {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 1rem;
    padding-top: 2rem;
}
#s9services ul {
    display: inline-block;
    font-size: 14px;
    line-height: 80%;
    
}
#s9services ul li {
    padding-top: 10px;
    list-style: none;
    
}

#s9adresse {
    display: flex;
    flex-direction: row;
    align-items: flex-start;   
    width: 100%;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 1rem;
  
    
}

.s9adresse1 {
    padding-right: 1rem;
}

#adresse{
    width: 100%;
}
#adresseBz {
    width:100%;
    order: 3;
}
#adressePn {
    width: 100%;
    order:4;
}
#addremail {
    width: 100%;
    order:1;

}
.sept{
    width: 100%;
    order: 5;
}
#ouverture {

    width: 100%;
   
}
#daysopened {
    width: 100%;
   
}
#services, #adresse,#mail, #ouverture, #newsletters {
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 8px;
    padding-top: 8px;
} 
#ouverture {
text-transform: uppercase;
}
#newsletters {
margin-bottom: 1rem;

width: 100%;
font-size: 1.5rem;
text-align: center;

}

.adresses {
font-weight: 500;
font-size: 16px;
display: block;
margin-bottom: 8px;
}

#s9adresse div,#lescontacts{
    font-size: 14px;  
}
#adresseBz,#adressePn,#addremail,#daysopned {
margin-bottom: 12px;

}

#mail {
display: block;
margin-bottom: 1px;
}


#s9news{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    order: -1;
    background-color: #01215D;
    padding: 2rem 0rem 0rem 0rem;

}

.inputs {
border-radius: 14px;
width: 100%;
height: 2.5rem;
margin : 0 0 20px 0;
padding-left: 30px;
border-style: none;
border: #fff 1px solid;
color: #fff;
font-size: 16px;
outline: none;
background-color: rgba(0, 0, 0, 0);

}

#s9news .inputs::placeholder {
 color: #898989;

}

#checkboxdiv {
display: flex;
align-items: flex-start;
margin-top: 8px;
margin-bottom:16px;
font-size: 12px;
}
#checkboxfdiv {
display: inline;
margin-right: 12px;
margin-top: 6px;

}
#checkboxf {
width: 20px;
height: 20px;
border-radius: 50px;
/*clip-path: circle(52% at 50% 50%);*/

}

#abobtn {
width: 100%;
height:40px ;
background-color: #ff0000;
text-align: center;
cursor: pointer;
padding-top: 8px;
padding-bottom: 20px;
border-radius: 10px;
}

#footer {
width: 100%;


}
.footerbg {
background-color:#01215D;
padding-top: 20px;
padding-bottom: 20px;
}

#footer,#footer a {
color: #EAF6FE;
padding: 0.3rem;
text-decoration: none;
}

#footer ul li {
padding-right:4rem;
padding-left: 0.5rem;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
font-size: 0.7rem;
width: 100%;
}

#flist {
list-style-type: none;
display: flex;
flex-direction: column;
}

 }
     