@media screen and (width>=920px) {

#Navbar {
  display: flex;
  position:fixed;
  top: 0;
  background-color: #fff;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 999;
  text-transform: uppercase;
    
}

#Navbar ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    
}

#Navbar ul li a {
    display: block;
    color: black;
    text-align: center;
    padding: 40px 45px;
    text-decoration: none;
  }
  

  #Navbar  li:hover , #Navbar  li:hover a {
   background-color:#ededed;
   transition: 0.5s;
 }


#logo{
    padding-right: 5%;
    
}

#contacts {
  align-self: flex-end;
  margin-bottom: 1rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #E20613;
  margin-left: 2rem;

}

#dispo {
  font-size: 0.6rem;
  color: #000;
}


 #nos_nums {
   /*fdisplay: flex;
 lex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  margin-bottom: 0.2rem;*/
  margin-bottom: 1rem;

}

#nos_nums a {
  font-size: 1rem;
  text-align: center;
  background-color: #fff;
  color: #E20613;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  border:0.1rem #003393 solid;
  margin: 0.1rem 0.3rem;
  transition: 0.3s;
  width: 100%;
}
#nos_nums a:hover {
  background-color: #003393;
  color: #fff;
}
#Navbar a {
    text-decoration: none;
    /*color: #E20613;*/
}
#Navbarmobile {
  display: none;
}
}

@media  screen and (width<920px) and (width>468px){
  #Navbar{
    display: none;
  }

  #Navbarmobile {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 999;
    background-color: #fff;
    padding:1rem 2rem;
    border-bottom: #003393 4px solid;
    
  }

  #logo {
    padding-left: 0.2rem;
   
  }
  #logo img {
    width: 100%;
  }
  #mobilenav {
    padding: 1rem 0.2rem 1rem 0.2rem;
    position: relative;
  }
  #ham {
    cursor: pointer;
    text-align: center;
  }
  #ham img {
    width: 100%;
  }

.hide{
  /*display: none;
  visibility: collapse;*/
  opacity: 0;
  transition:5s;
  pointer-events: none;
  
  
}

.menuderoulant {
  position: absolute;
  top: 6.5rem;
  right: 0;
  background-color: #003393;
  padding:1rem 1rem 2rem 1rem;
  margin-right: 0.6rem;
  font-size: 1rem;
  border-radius: 0rem 0rem 1rem 1rem;
  border-bottom: #fff 1px solid;
  border-left: #fff 1px solid;
  border-right: #fff 1px solid;
  transition: 0.3s;
 
}

.menuderoulant ul {
   color: #fff;
  text-transform: uppercase;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;


}
.menuderoulant ul a{
  color: #fff;
  text-decoration: none;


}
.menuderoulant ul li{
  padding: 0.5rem 2rem;
  display: flex;
  align-items: baseline;
  color: #b7b7b7;
  
}
.menuderoulant ul li:hover{
  
  color: #fff;
  font-weight: 900;
  transition: 0.3s;
  
}
.menuderoulant ul li:hover img{
  
  filter:contrast(100%);
  transition: 0.3s;
  
}
.menuderoulant ul li a[class='active'] img{
  
  filter:contrast(100%);
  transition: 0.3s;
  
}
.menuderoulant ul li img{
  padding-right: 1rem;
  filter:contrast(5%)
}
#bloc_call {
  color: #fff;
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

}

#numb_to_call {
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  
}
#zip {
  border: #fff 1px solid;
  border-radius: 1rem;
  padding: 0.5rem 0.5rem;
  display: inline-block;
  margin-right: 0.3rem;
  font-size: 1rem;

}
#central_part {
  background-color: #f00;
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  font-weight: 700;
  font-size: 1.1rem;

}

}

@media screen and (width<=468px) {
  #Navbar{
    display: none;
  }


  #Navbarmobile {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 999;
    background-color: #fff;
    padding:0.5rem 0.5rem;
    border-bottom: #003393 4px solid;
    
  }

  #logo {
    padding-left: 0.2rem;
   
  }
  #logo img {
    width: 70%;
  }
  #mobilenav {
    padding: 1rem 0.2rem 1rem 0.2rem;
    position: relative;
  }
  #ham {
    cursor: pointer;
    text-align: center;
  }
  #ham img {
    width: 70%;
  }

.hide{
  /*display: none;
  visibility: collapse;*/
  opacity: 0;
  transition:5s;
  pointer-events: none;
  
  
}

.menuderoulant {
  position: absolute;
  top: 4.6rem;
  right: 0;
  background-color: #003393;
  padding:1rem 1rem 2rem 1rem;
  margin-right: 0.6rem;
  font-size: 0.8rem;
  border-radius: 0rem 0rem 1rem 1rem;
  border-bottom: #fff 1px solid;
  border-left: #fff 1px solid;
  border-right: #fff 1px solid;
  transition: 0.3s;
 
}

.menuderoulant ul {
   color: #fff;
  text-transform: uppercase;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;


}
.menuderoulant ul a{
  color: #fff;
  text-decoration: none;


}
.menuderoulant ul li{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: baseline;
  color: #b7b7b7;
  
}
.menuderoulant ul li:hover{
  
  color: #fff;
  font-weight: 900;
  transition: 0.3s;
  
}
.menuderoulant ul li:hover img{
  
  filter:contrast(100%);
  transition: 0.3s;
  
}
.menuderoulant ul li a[class='active'] img{
  
  filter:contrast(100%);
  transition: 0.3s;
  
}
.menuderoulant ul li img{
  padding-right: 1rem;
  filter:contrast(5%)
}
#bloc_call {
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

}
#numb_to_call {
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  
}
#zip {
  border: #fff 1px solid;
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 0.7rem;

}
#central_part {
  background-color: #f00;
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  font-weight: 700;
  font-size: 1.1rem;

}

}
