#Hide {
    display: none;
    transition-duration:2s;
}
.position {
    position: fixed;
    top: 35vh;
    
    

}
#boite {
   /* width: fit-content;
    height: fit-content;*/
    width: 30vw;
    height:20vw;
    background-color: #003393;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*padding: 2rem 4rem 1rem 4rem;*/
    padding: 6vw 6vw 3vw 6vw;
    box-shadow: 0px 0px 2vw #9cbfff;
    
}
#follow {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1vw;
    width: fit-content;
    height: fit-content;
}
#imgfollow {
    display: flex;
    align-items: center;
}

#imgfollow img {
    width: 4vw;
    height:4vw;
    cursor: pointer;
    margin-right:0.5vw;
}
#imgfollow img:hover {
    box-shadow: 0px 0px 1vh #fff;
    border-radius: 1vw;
    width: 5vw;
    height: 5vw;
    transition: 0.3s;

}
#follow span{
    font-size: 1.4vw;
    font-weight: 500;   
    padding-top: 0.5vw;
}
#boite span{
    font-size: 1vw;
    font-weight: 500;   
}

#boite button {
    color: red;
    font-size: 1.2vw;
    font-weight: 700;
    padding: 1vw 4vw;
    border-radius: 1vw;
    margin-top: 1vw;
    background: #fff;
    border: none;
    
}

#boite button:hover {
    box-shadow: 0px 0px 2vw #fff;
    font-size: 1.3vw;
    padding: 1.2vw 5vw;
    transition: 0.3s;

}