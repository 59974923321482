@media screen and (width>=920px){

    #divformulaire_popup_devis {
        background-color: #fff;
        /*width: 1080px;
        height: 950px;*/
        width: 50vw;
        height: 80vh;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        box-shadow: 0.5rem 0.5rem 5rem hsla(0, 0%, 5%, 0.315);
        position: relative;
    
    }
    #fermeture {
        width: 1.5vw;
        height: 1.5vw;
        border-radius: 50%;
        background-color: #a60202;
        position: absolute;
        top: 0;
        right: 0;
        margin: 1vw 1vw;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        color: #fff;
        
    }
    #fermeture:hover {
        width: 1.8vw;
        height: 1.8vw;
        background-color: #f00;
        box-shadow: 0rem 0rem 0.5rem rgb(254, 93, 93);
        transition: 0.2s;
    }
    #leftside {
        width: 50%;
        height: 100%;
        background-image: url(../../assets/image_popup.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: #004fb7;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding:0vh 0vw 0vh 6vw;
      
    }
    #logogram {
        display: block;
        /*width: 10vw;
        height: 10vw;*/
        width: 8vw;
        height: 8vw;
    
    }
    #leftside #logogram img {
        width: 100%;
        height: 100%;
        
    }
    #leftside p{
        font-family: Estelle;
        font-size: 2.5vw;
        font-weight: 400;
        line-height: 3vw;
        text-align: left;
        padding-top:3vh;
        
    }
    #leftside .un{
        font-family: Poppins ;
        font-style: normal;
        font-weight: 900;
        /*font-size: 2.5vw;*/
        font-size: 2vw;
        padding-top: 6vh;
        padding-bottom :1vh; 
    
    }
    #leftside .deux{
        font-family: Poppins ;
        font-style: normal;
        font-weight: 300;
        /*font-size: 1.2vw;*/
        font-size: 1vw;
    
    }
    #rightside {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
       
    }
    #rightsidediv {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10vh 0vw 0vh 0vw;
    }
    #rightsidediv h1 {
        color: #000;
        font-size: 1.5vw;
        font-weight: 700;
        text-transform: uppercase;
    }
    #formulaire_popup_devis {
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1vh;
        
    }
    #popup_Prenom_devis {
        margin-bottom: 3vh;
    }
    
    #formulaire_popup_devis input{
        margin-top: 3vh;
        padding:2vh 2vw;
        border-radius: 4vw;
        border: #003393 0.2vh solid;
        font-size: 2vh;
        color: #003393;
        width: 14vw/*250px*/;
        height: 5vh/*50px*/;
        background-color: rgba(0, 0, 0, 0);
        outline: none;
        
    }
    #popup_msg_devis {
        margin-top: 3vh;
        padding:2vh 2vw;
        border-radius: 2vw;
        width: 14vw;
        height: 16vh;
        font-size: 2vh;
        font-family: poppins;
        text-align: start;
        resize: none;
        outline: none;
        
    }
    #formulaire_popup_devis input[class='failed'] {
        margin-top: 3vh;
        padding:2vh 2vw;
        border-radius: 4vw;
        border: #f00 0.2vh solid;
        box-shadow:0px 0px 1vw #f00;
        font-size: 2vh;
        color: #f00;
        width: 14vw/*250px*/;
        height: 5vh/*50px*/;
        background-color: #fcdddd;
        outline: none;
        transition: 1s;
        
    }
    
    #checkboxdivpopup {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.2vh;
        font-weight: bold;
        margin: 2vh 5vw;
    }
    #checkboxfpopup {
        width: 2vh;
        height: 2vh;
        margin-right: 0.5vw;
        cursor: pointer;
    }
    .agree {
        color: #f00;
        /*border: #f00 1px solid;*/
        text-decoration: underline;
        transition: 1s;
    }
    #abobtnpop {
        width: 14vw;
        border-radius: 1vh;
        background-color: #f00;
        font-size: 1.2vw;
        color: #fff;
        text-align: center;
        padding: 1vh 2vw;
        cursor: pointer;   
        
    }
    #abobtnpop:hover{
        width: 15vw;
        padding: 1.5vh 2vw;
        transition: 0.3s;
        
        
    }
    
    div[id='divformulaire_popup_devis'] input::placeholder,div[id='divformulaire_popup_devis'] textarea::placeholder{
        color: #444;
        font-family: poppins;
        
      }
    textarea {
        border: #003393 0.2vh solid;
        background-color: rgba(0, 0, 0, 0);
    }
    .failed{
        border: #f00 0.2vh solid;
        box-shadow:0px 0px 1vw #f00;
        color: #f00;
        background-color: #fcdddd;
        transition: 1s;
    }
    
}
@media  screen and (width<920px) and (width>468px){    
 
 #popup_tel_devis::placeholder{
    padding-left: 2rem;
 }

    #divformulaire_popup_devis {
        background-color: #fff;
        width:80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0.5rem 0.5rem 5rem hsla(0, 0%, 5%, 0.315);
        position: relative;
    
    }
    #fermeture {
        color: #fff;
        width: 6vw;
        height: 6vw;
        border-radius: 50%;
        background-color: #f00;
        box-shadow:0px 0px 5px rgb(255, 196, 196) ;
        position: absolute;
        top: 0;
        right: 0;
        margin: 2vw 2vw;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        
    }
    #fermeture:hover {
        width: 7vw;
        height: 7vw;
        background-color: #f00;
        box-shadow: 0rem 0rem 0.5rem rgb(254, 93, 93);
        transition: 0.2s;
    }
    #leftside {
        background-image: url(../../assets/image_popup.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: #004fb7;
        color: #fff;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        padding:1rem 2rem;
        width: 100%;
      
    }
    #logogram {
        display: block;
        width: 12vw;
        height: 12vw;
        margin-right: 0.5rem;
    
    }
    #leftside #logogram img {
        width: 100%;
        height: 100%;
        
    }
    #leftside p{
        font-family: Estelle;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1rem;
        text-align: left;
        padding-top:3vh;
        
    }
    #leftside .un{
        display: none;
    
    }
    #leftside .deux{
    
        display: none;
    
    }
    #rightside {
      
        display: flex;
        justify-content: center;
        padding: 1rem 2rem;
       
    }
    #rightsidediv {
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    #rightsidediv h1 {
        color: #000;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
    }
    #formulaire_popup_devis {
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1vh;
        
    }
    #popup_Prenom_devis {
        margin-bottom: 2vh;
    }
    
    #formulaire_popup_devis input{
        margin-top: 2vh;
        padding:2vh 2vw;
        border-radius: 4vw;
        border: #003393 0.2vh solid;
        font-size: 2vh;
        color: #003393;
        width: 100%;
        height: 5vh;
        background-color: rgba(0, 0, 0, 0);
        outline: none;
        
    }
    #popup_msg_devis {
        margin-top: 3vh;
        padding:2vh 2vw;
        border-radius: 2vw;
        width: 100%;
        height: 16vh;
        font-size: 2vh;
        font-family: poppins;
        text-align: start;
        resize: none;
        outline: none;
        
    }
    #formulaire_popup_devis input[class='failed'] {
       
        border-radius: 4vw;
        border: #f00 0.2vh solid;
        box-shadow:0px 0px 1vw #f00;
        font-size: 2vh;
        color: #f00;
        background-color: #fcdddd;
        outline: none;
        transition: 1s;
        
    }
    
    #checkboxdivpopup {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.2vh;
        font-weight: bold;
        margin: 2vh 5vw;
    }
    #checkboxfpopup {
        margin-right: 0.5vw;
        cursor: pointer;
    }
    .agree {
        color: #f00;
        text-decoration: underline;
        transition: 1s;
    }
    #abobtnpop {
        width: 100%;
        border-radius: 1vh;
        background-color: #f00;
        font-size: 1rem;
        color: #fff;
        text-align: center;
        padding: 1vh 2vw;
        cursor: pointer;   
        
    }
    #abobtnpop:hover{

        padding: 1.5vh 2vw;
        transition: 0.3s;
        
        
    }
    
    div[id='divformulaire_popup_devis'] input::placeholder,div[id='divformulaire_popup_devis'] textarea::placeholder{
        color: #444;
        font-family: poppins;
        
      }
    textarea {
        border: #003393 0.2vh solid;
        background-color: rgba(0, 0, 0, 0);
    }
    .failed{
        border: #f00 0.2vh solid;
        box-shadow:0px 0px 1vw #f00;
        color: #f00;
        background-color: #fcdddd;
        transition: 1s;
    }
}
@media  screen and (width<=468px){   
   #selecteur{
    width: 20%;
    height: 4vh;
    margin-top: 2rem;
   }
   #popup_tel_devis {
    padding-left: 2rem;

 }
 #popup_tel_devis::placeholder{
    padding-left: 2rem;
 }
    #divformulaire_popup_devis {
        background-color: #fff;
        width:80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0.5rem 0.5rem 5rem hsla(0, 0%, 5%, 0.315);
        position: relative;
    
    }
    #fermeture {
        color: #fff;
        width: 6vw;
        height: 6vw;
        border-radius: 50%;
        background-color: #f00;
        box-shadow:0px 0px 5px rgb(255, 196, 196) ;
        position: absolute;
        top: 0;
        right: 0;
        margin: 2vw 2vw;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        
    }
    #fermeture:hover {
        width: 7vw;
        height: 7vw;
        background-color: #f00;
        box-shadow: 0rem 0rem 0.5rem rgb(254, 93, 93);
        transition: 0.2s;
    }
    #leftside {
        background-image: url(../../assets/image_popup.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: #004fb7;
        color: #fff;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        padding:1rem 2rem;
        width: 100%;
      
    }
    #logogram {
        display: block;
        width: 12vw;
        height: 12vw;
        margin-right: 0.5rem;
    
    }
    #leftside #logogram img {
        width: 100%;
        height: 100%;
        
    }
    #leftside p{
        font-family: Estelle;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1rem;
        text-align: left;
        padding-top:3vh;
        
    }
    #leftside .un{
        display: none;
    
    }
    #leftside .deux{
    
        display: none;
    
    }
    #rightside {
      
        display: flex;
        justify-content: center;
        padding: 1rem 2rem;
       
    }
    #rightsidediv {
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    #rightsidediv h1 {
        color: #000;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
    }
    #formulaire_popup_devis {
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1vh;
        
    }
    #popup_Prenom_devis {
        margin-bottom: 2vh;
    }
    
    #formulaire_popup_devis input{
        margin-top: 2vh;
        padding:2vh 2vw;
        border-radius: 4vw;
        border: #003393 0.2vh solid;
        font-size: 2vh;
        color: #003393;
        width: 100%;
        height: 5vh;
        background-color: rgba(0, 0, 0, 0);
        outline: none;
        
    }
    #popup_msg_devis {
        margin-top: 3vh;
        padding:2vh 2vw;
        border-radius: 2vw;
        width: 100%;
        height: 16vh;
        font-size: 2vh;
        font-family: poppins;
        text-align: start;
        resize: none;
        outline: none;
        
    }
    #formulaire_popup_devis input[class='failed'] {
       
        border-radius: 4vw;
        border: #f00 0.2vh solid;
        box-shadow:0px 0px 1vw #f00;
        font-size: 2vh;
        color: #f00;
        background-color: #fcdddd;
        outline: none;
        transition: 1s;
        
    }
    
    #checkboxdivpopup {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.2vh;
        font-weight: bold;
        margin: 2vh 5vw;
    }
    #checkboxfpopup {
        margin-right: 0.5vw;
        cursor: pointer;
    }
    .agree {
        color: #f00;
        text-decoration: underline;
        transition: 1s;
    }
    #abobtnpop {
        width: 100%;
        border-radius: 1vh;
        background-color: #f00;
        font-size: 1rem;
        color: #fff;
        text-align: center;
        padding: 1vh 2vw;
        cursor: pointer;   
        
    }
    #abobtnpop:hover{

        padding: 1.5vh 2vw;
        transition: 0.3s;
        
        
    }
    
    div[id='divformulaire_popup_devis'] input::placeholder,div[id='divformulaire_popup_devis'] textarea::placeholder{
        color: #444;
        font-family: poppins;
        
      }
    textarea {
        border: #003393 0.2vh solid;
        background-color: rgba(0, 0, 0, 0);
    }
    .failed{
        border: #f00 0.2vh solid;
        box-shadow:0px 0px 1vw #f00;
        color: #f00;
        background-color: #fcdddd;
        transition: 1s;
    }

}

.iti__flag {background-image: url("../../../node_modules/intl-tel-input/build/img/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("../../../node_modules/intl-tel-input/build/img/flags@2x.png");}
}

 /* #formulaire_popup_devis div {
  border-radius: 50%;
}
#flaggie {
    background-image: url('../../../node_modules/intl-tel-input/build/img/flags.png');
    width: 20px;
    height: 10px;
    border-color: red;
    padding-left: 0.2vh;
    background-color: #f00;
    justify-content: center;
    align-items: center;

}*/