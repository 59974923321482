
.buttondevis {
    color: #fff;
    background-color:#E20613;
    padding: 1.2rem 3rem;
    width: fit-content;
    height: fit-content;
    border-radius: 2rem;
    font-size: 16px;
    cursor: pointer;
    
}