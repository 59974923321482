#card {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
}

#mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0003;
    border-radius: 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-weight: 700;
}

#mask:hover{
    background-color:#004f9e;
    border-radius: 2.6rem;
    border: 2px #fff solid;
    transition: 0.3s;  
}

#imgll{
    width: 100%;
}
#imgill img{
    display: block;
    width: 100%;
    border-radius: 2.6rem;

}
#textactivite{
    padding:0rem 1rem;
}

