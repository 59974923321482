@media screen and (width>=920px) {
#ML{
    
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 10rem 20rem 2.5rem 20rem;

}

#ML * {
    margin-bottom: 2rem;
}
#ML span {
    font-weight: 900;
}

}
@media screen and (width<920px) and (width>468px) {
    #ML{
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin: 10rem 2rem 2rem 2rem;
    
    }
    
    #ML * {
        margin-bottom: 2rem;
    }
    #ML span {
        font-weight: 900;
    }

}
@media  screen and (width<=468px)  {
    #ML{
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin: 10rem 2rem 2rem 2rem;
    
    }
    
    #ML * {
        margin-bottom: 2rem;
    }
    #ML span {
        font-weight: 900;
    }

}