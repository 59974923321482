#encard:hover {
    transition: 0.3s;
    background-color: #003393;
    color: white;
}
#encard:hover h1{
    transition: 0.3s;
    color: white;

}


@media screen and (width<=468px) {
    #encard {
        max-width: 350px;
        max-height: 566px;
        margin: 1rem 0rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border: 3px solid #003393;
        border-radius:2rem ;
        padding: 2rem 2rem;
    
    }
    #encard h1 {
        font-size: 2rem;
        color: #003393;
        padding: 1rem 0rem;
        line-height: 120%;
        text-transform: uppercase;
    
    }
    
    #encard p {
        font-size: 1rem;
    
    }
    
}
@media screen and (width>=468px) {
#encard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 3px solid #003393;
    border-radius:2rem ;
    padding: 2rem 2rem;
    width:364px ;
    
}
#encard h1 {
    font-size: 2rem;
    color: #003393;
    padding: 1rem 0rem;
    line-height: 120%;
    text-transform: uppercase;

}

#encard p {
    font-size: 1rem;

}
}