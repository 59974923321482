@media screen and (width>=920px) {
#DPC {
    display: flex;
    align-items: flex-start;
    width: 1152px;
    margin-top: 10rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0rem 1rem;

    
    
}
#DPC_content {
    margin-right: 2rem;
    margin-top:0px ;
    margin-bottom: 5rem;
}
#DPC_content h1 {
    font-size: 36px;
    margin-bottom: 2rem;
}
#donp{
    margin-bottom: 2rem;
}
.DPC_content_h2 {
    font-size: 24px;
    margin-bottom: 1rem;
}
#DPC_content div p {
    font-size: 16px;
    margin-bottom: 1rem;
}
}
@media screen and (width<920px) and (width>468px)  {
    #DPC {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 10rem;
        margin-bottom: 2rem;
        padding: 0rem 1rem;
    
        
        
    }
    #DPC_content {
        margin-right: 2rem;
        margin-top:0px ;
        margin-bottom: 5rem;
    }
    #DPC_content h1 {
        font-size: 36px;
        margin-bottom: 2rem;
    }
    #donp{
        margin-bottom: 2rem;
    }
    .DPC_content_h2 {
        font-size: 24px;
        margin-bottom: 1rem;
    }
    #DPC_content div p {
        font-size: 16px;
        margin-bottom: 1rem;
    }
    #DPC div[id='divformulaire_mini_devis'] {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

}

@media  screen and (width<=468px) {
    #DPC {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 10rem;
        margin-bottom: 2rem;
        padding: 0rem 1rem;
    
        
        
    }
    #DPC_content {
        margin-right: 2rem;
        margin-top:0px ;
        margin-bottom: 5rem;
    }
    #DPC_content h1 {
        font-size: 36px;
        margin-bottom: 2rem;
    }
    #donp{
        margin-bottom: 2rem;
    }
    .DPC_content_h2 {
        font-size: 24px;
        margin-bottom: 1rem;
    }
    #DPC_content div p {
        font-size: 16px;
        margin-bottom: 1rem;
    }
    #DPC div[id='divformulaire_mini_devis'] {
        width: 100%;
    }

}