#membre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 100px;
}


#membre span {
    font-size: 16px;
    font-weight: 900;
    text-align: center;

}

#membre img {
    width: 15rem;
    height: 15rem;
    margin-bottom: 20px;
}
#membre p{
    text-align: center;
}