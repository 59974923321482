@media screen and (width>=920px) {
#Hide{
    display: none;
    transition: 2s;

}
#Hidepas {
    display: block;
}
#Home {
    margin-top: 150px;
}
#Homesection1 {
    color:#003393;
    display: flex;
    
    margin-top:4rem ;
    margin-bottom: 5rem;
    width: 1152px;
    margin-left: auto;
    margin-right: auto;
    
}
#Homesection1agauche {
    display: flex;
    justify-content: flex-start;
    width: 50%;
}
#griphe {
    padding-top: 8px;
    padding-right: 16px;
}
.container {
    display: flex;
    flex-direction: column; 
}
.text {
        /*font-size: 47px;
        line-height: 58px*/
        font-size: 40px;
        font-family: Poppins;
        font-weight: 500;
        margin-bottom:5rem ;
        line-height: 120%;
}
#text_marked { 
        font-weight: 900;
        padding-right: 2%;
        text-transform: uppercase;
    
}
.Homesection1adroite {
    width: 50%;
}
.Homesection1adroite img {
    width: 100%;
}  
#Homesection2 {
    background-color: #003393;
    padding-bottom: 5rem;
}
#flexeur_container {
    position: relative;
    width: 1152px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10rem;
} 
#flexeur {
    display: flex;
    justify-content:space-between;
    position: absolute;
    top: -12%;/*-15% -10%*/
    width: 100%;

 }
#col {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0rem 1rem;
    
}
#text {
    
    display: flex;
    flex-direction: column;
    margin-top: 12rem;
    width: 50%;
    font-size: 2rem;
    
}
#text h1 {
    color: aliceblue;
    /*font-size: 40px;*/
    font-size:2.3rem;
    margin-bottom: 10%;
   font-weight: 500;
}
#text p {
    margin-bottom: 4rem;
    color: #00FFFF;
    /*font-size: 36px;*/
    font-size:1em;
    font-weight: 700;
    line-height: 2.7rem;
}
#text p span {
    font-style: italic;
}
#carte {
    display: flex;
    /*justify-content: flex-end;
    align-items: center;
    margin-top: 12rem;*/
    align-items: center;
    margin-top: 6rem;
    margin-left: 6rem;
    width: 50%;
}
#carte img{
    width: 458px;
    height: 534px;
}
#Homesection3 {
    width: 1152px;
    margin-left: auto;
    margin-right: auto;
    padding: 5rem 1rem 0rem 1rem;

}
.row1 {
        display: flex;
        gap: 1rem;
        /*margin-top: 2rem;*/
        justify-content: center;
}
.div36{
    width: 36%;
   
}
.row1 p#objectif {
        color: red;
        font-size: 2.1rem;
        font-weight: 800;
        line-height: 2.8rem;
        padding-right: 2rem;
}
.row2 {
        display: flex;
        gap: 1rem;
        margin-top: 1rem;
        justify-content: center;
}
p#one p#two {
        margin: 5rem 2rem 1rem 0rem;
        
} 
#one,#two {
    font-size: 1rem;
    font-weight: 500;
    padding-right: 2rem;
    padding-top: 0.5rem;
} 
.row3 {
        display: flex;
        gap: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        justify-content:flex-end;
}
#row3img {
    /*display: contents;*/
    width: 70%;
}
.bg {
    justify-items: flex-start;
    width: 100%;
    /*overflow: hidden;*/           
}
#Homesection4 {
    /*width: 1152px;*/
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
}  
.Section4 {
    position: relative;
    /*width: 1928px;*/
    height: 1066px;
    background-image: url('../../assets/cover.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    
}
.cadrebleu {
    font-size: 2rem;
    color: #fff;
    width:50rem;
    height: fit-content;
    background-color:rgba(0, 51, 147, 0.74);
    padding: 3rem 5rem 3rem 5rem;
    border-radius: 2rem;
    position: absolute;
    left:calc(50vw - 25rem);
    bottom:6.5rem;
    
    /* old cadre bleu
    width:40rem;
    padding: 1rem 3rem 1rem 3rem;
    left: 21rem;
    bottom:3.7rem;
    */
    

}
.cadrebleu span {
    display: block;
    text-align: justify;
    color: red;
    font-weight: 700;
    padding: 0.3rem 0rem;
}
.cadrebleu span:first-child {
    display: block;
    text-align: justify;
    color: #fff;
    /*font-size: 0.3em;*/
    font-size: 1.2rem;
    line-height: 2rem;
    padding-bottom: 1.2rem;
}
.cadrebleu .send {
    display: block;
    text-align: justify;
    color: #fff;
    font-size: 2.3rem;
    line-height: 2.7rem;
    padding-bottom: 1.2rem;
}

#Homesection5 {
    display: flex;
    width: 1152px;
    margin-right: auto;
    margin-left: auto;
    padding: 5rem 1rem 100px 1rem;
    padding-bottom: 100px;
}
.item1 {
    
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 2.5rem;

}
#Ibs{
    font-size: 2.3rem;
    font-weight: 500;
    line-height: 3.2rem;
    letter-spacing: 2%;
    margin-bottom: 2rem;
    padding-right:1.5rem;
    
}
.item1 span#IBS {
    color: red;
    font-weight: 700;
}
.item2 {
    width: 50%;   
}
.item2 img {
    width: 100%;   
}


#Homesection6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1152px;
    margin-left: auto;
    margin-right: auto;
}
.rowimg1,.rowimg2{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0rem 2rem;

}
.rowimg1 img{
    width: 100%;
    margin-bottom: 2rem;
    cursor: pointer;
}
.rowimg2 img{
    margin-bottom: 2rem;
    cursor: pointer;
    
}
#Homesection6 span {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: 700;

}
#Homesection6 span span {
    color: red;
}

#Homesection7 {
    background-color: #A1DAF8;
    
}
#blocbleu {
    display: flex;
    width: 1152px;
    margin-left: auto;
    margin-right: auto;
}
#blocbleu p {
    width: 50%;
    margin:5rem  1rem 5rem 2rem ;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 2rem;
    letter-spacing: 5%;
}


#Homesection8 {
    background-color: #003393;
    padding-top: 5rem;
    padding-bottom: 5rem;
}
#Homesection8_center {
    width: 1152px;
    margin-left: auto;
    margin-right: auto;
}

.kati {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.kati h1 {
    font-size: 2.5rem;
    line-height: 0.7rem;
    letter-spacing: 5%;
    padding-bottom: 1.7rem;
    color: aliceblue;
    
 }
 .kati p {

    color: aliceblue;
   padding-bottom: 4rem;

 }

.flexrow {
    display: flex;
    width: 100%;
    justify-content:center;

}

#Homesection9 {
    width: 1152px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
    
}
   
#Homesection9 h1 {
       padding-bottom: 1rem;
       font-size: 2.5rem;
       line-height: 1.8rem;
} 
.flexpartenaire {
    display: flex;
    justify-content: center;
    align-items:center;
    margin-top: 1.2rem;
    width: 85%;
 }
.flexpartenaire img {
    margin: 1rem;
    overflow: hidden;
}


}
@media  screen and (width<920px) and (width>468px) {
    #Hide{
        display: none;
        transition: 2s;
    
    }
    #Hidepas {
        display: block;
    }
      
    #Home{
        width: 100%;
        margin-top: 100px;
    }
    #Homesection1 {
        color:#fff;
        display: flex;
        margin-top:6.6rem ;
        margin-bottom: 1rem;
        align-items: center;
        justify-content: center;
        
        
    }
    #Homesection1agauche {
        
        width: 50%;
        margin-left: 2rem;
      
    }
    
    #griphe {
        display: none;
    }
    .container {
        display: flex;
        flex-direction: column; 
        align-items: flex-start;
    }
    .text {

     font-size:2.2rem;
     font-family: Poppins;
     text-align: justify;
     font-weight: 500;
     margin-bottom:1.5rem ;
     padding-top: 2rem;
     padding-right: 1rem;
     line-height: 120%;
     color: #003393;
     width: 90%;
    
    }
    .text br{
        display:contents;
    }
    #text_marked { 
            font-weight: 900;
            padding-right: 2%;
            text-transform: uppercase;
        
    }
    .Homesection1adroite {
        width:50%;
    }
    .Homesection1adroite img {
        width: 100%;
    }
    #Homesection2 {
        padding-bottom: 1rem;
    }
    /*#flexeur_container {
        position: relative;
        margin-bottom: 1rem;
       
    } 
    #flexeur {
        display: flex;
        justify-content:space-evenly;
        flex-wrap: wrap;
        width:70%;
        margin: 2rem auto;
        
     }
     #flexeur div[id='card'] {
        margin: 2rem 1rem;
    
     }*/

     /*a modifier*/
     #flexeur_container {
        position: relative;
        margin-bottom: 1rem;
        
       
    } 
    #flexeur {
        display: flex;
        justify-content:space-evenly;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 1rem;
    
     }
     #flexeur div[id='card'] {
        width: 20%;
        margin-top: 1rem;
        margin-bottom: 1rem;
       
     }
   

     #col {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #003393;
        padding-top: 4rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 4rem;
        
    }
    #text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 50%;
        padding-right: 2rem;
     
    }
   
    #text h1 {
    
       display: none;
    }
    #text p {
        margin-bottom:2rem;
        color: #00FFFF;
        font-size:1.8em;
        font-weight: 700;
        line-height: 2.5rem;
    }
    #text p span {
        font-style: italic;
    }
    #carte {
        display: flex;
        width: 50%;
        padding: 1rem 0rem 4rem 0rem ;
    }
    #carte img{
        width: 100%;
    }
    
    #Homesection3 {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        padding-top:2rem;
        margin: 0rem auto;
    }
    .row1 {
        display: contents;
        width: 100%;
    }
    .row1 div[class='div36'] {
        order:-2;
        padding-bottom: 1rem;
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    .row1 p#objectif {
            color: red;
            font-size: 2.1rem;
            font-weight: 800;
            line-height: 2.8rem;
            padding-right: 2rem;
    }
    .row2 {
            display: contents;
            margin-top: 1rem;
    }
    .row2 div[class='div36'] {
        order:-1;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 50%;
    }
    p#one p#two {
            margin: 5rem 2rem 1rem 0rem;
            
    } 
    #one,#two {
        font-size: 1rem;
        font-weight: 500;
        padding-right: 2rem;
        padding-top: 0.5rem;
    } 
    .row3 {
            display: contents;
            margin-top: 1rem;
        
    }
    #row3img {
        order: 9;;
    }
    
    .bg {
        width: 100%;
        order: 9;
        padding: 0px;
        /*overflow: hidden;*/           
    }
    
    #encard {
        margin: 1rem 1rem;
        
    }
    #Homesection4 {
        display: block;
        width: 100%;
        height:450px ;
        margin-bottom: 40vh;
    }  
    .Section4 {
        position: relative;
        background-image: url('../../assets/cover.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        
    }
    .cadrebleu {
        font-size: 1rem;
        color: #fff;
        background-color:#003393;
        padding: 3rem 6rem ;
        border-radius: 4rem;
        margin: 1rem;
        position: absolute;
        bottom: -35vh;
        left: 10vw;
        width: 80%;
        
    
    }
    .cadrebleu span {
        display: block;
        font-size: 2rem;
        width: 60%;
        color: red;
        font-weight: 700;
        padding: 0.3rem 0rem;
    }
    .cadrebleu span:first-child {
        display: block;
        color: #fff;
        width: 100%;
        /*font-size: 0.3em;*/
        font-size: 1rem;
        line-height: 1rem;
        padding-bottom: 1rem;
    }
    .cadrebleu .send {
        display: block;
        color: #fff;
        width: 100%;
        font-size: 2rem;
        line-height: 2.7rem;
        padding-bottom: 1.2rem;
    }
    .cadrebleu .send br{
        display: contents
    }
    
    #Homesection5 {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding-bottom:4rem;

    }
    .item1 {
        
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 2.5rem;
        font-size: 2.4rem;
    
    }
    #Ibs{
        
        font-weight: 500;
        line-height: 3.2rem;
        letter-spacing: 2%;
        margin-bottom: 2rem;
        align-self: center;
        padding: 0px 1rem;
        
        
    }
    
    .item1 span#IBS {
        color: red;
        font-weight: 700;
    }
    .item2 {
        width: 100%; 
        padding:0rem 1rem;
          
    }
    .item2 img {
        width: 100%;  
        
    }
    
    #Homesection6 {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin-bottom: 4rem;
    }
    #Homesection6 span {
        text-align: center;
        margin-bottom: 1.5rem;
        font-size: 2.5rem;
        font-weight: 700;
        width: 100%;
    
    }
    #Homesection6 span span {
        color: red;
    }
    
   
    .rowimg1,.rowimg2{
        display: contents;
        
    }
    .rowimg1 img,.rowimg2 img{
        margin: 1rem 2rem;
       
        cursor: pointer;
    }
    

    #Homesection7 {
        background-color: #A1DAF8;
        margin-bottom: 4rem;
        
    }
    #blocbleu {
        display: flex;
        width: 80%;
        margin: 0rem auto;
        padding:4rem  1rem 2rem 1rem ;
    }
    #blocbleu p {
        margin-bottom: 2rem;
       padding-left: 2rem;
        font-size: 1rem;
        font-weight: 500;
    }
    #Homesection8 {
        background-color: #003393;
        padding-top: 2rem;
        padding-bottom: 8rem;
    }
    #Homesection8_center {
        width: 100%;
        
    }
    
    .kati {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .kati h1 {
        font-size: 1.5rem;
        color: aliceblue;
        margin-bottom: 1rem;
        
     }
     .kati p {
        font-size: 1.2rem;
        width: 70%;
        text-align: center;
        color: aliceblue;
       padding-bottom: 2rem;
    
     }
     .flexrow {
        display: flex;
        width: 100%;
        justify-content:center;
        align-items: center;
        overflow-x: scroll;
        padding: 0rem 4rem;
    }
    .flexrow::-webkit-scrollbar {
        background: transparent;
       
    }
    #Homesection9 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 2rem;
        padding-bottom: 2rem;
        
    }
       
    #Homesection9 h1 {
           padding-bottom: 1rem;
           font-size: 1.5rem;
    } 
    #Homesection9 p {
        width: 60%;
        text-align: center;
    }
    .flexpartenaire {
        display: flex;
        justify-content: center;
        align-items:center;
        margin-top: 1.2rem;
        width: 100%;
     }
    .flexpartenaire img {
        margin: 1rem;
        overflow: hidden;
    }
    
    ::-webkit-scrollbar{
        width: 0px;
    }
    ::-webkit-scrollbar-track{
        background: transparent;
    }
    
    
        
      }
@media screen and (width<=468px) {
#Hide{
    display: none;
    transition: 2s;

}
#Hidepas {
    display: block;
}
  
#Home{
    width: 100%;
    margin-top: 100px;
}
#Homesection1 {
    color:#fff;
    display: flex;
    flex-direction: column;
    margin-top:4rem ;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;
    
    
}
#Homesection1agauche {
    order: 2;
    width: 100%;
  
}

#griphe {
    display: none;
}
.container {
    display: flex;
    flex-direction: column; 
    align-items: center;
}
.text {
        /*font-size: 47px;
        line-height: 58px*/
        font-size:1.5rem;
        font-family: Poppins;
        text-align: justify;
        font-weight: 500;
        padding: 20px;
        margin-bottom:1rem ;
        line-height: 120%;
        background-color: #003393;
          
    width: 100%;
}
.text br{
    display:contents;
}
#text_marked { 
        font-weight: 900;
        padding-right: 2%;
        text-transform: uppercase;
    
}
.Homesection1adroite {
    width: 100%;
}
.Homesection1adroite img {
    width: 100%;
}
#Homesection2 {
    padding-bottom: 1rem;
}
#flexeur_container {
    position: relative;
    margin-bottom: 1rem;
    
   
} 
#flexeur {
    display: flex;
    justify-content:space-evenly;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1rem;

 }
 #flexeur div[id='card'] {
    width: 40%;
    margin-top: 1rem;
    margin-bottom: 1rem;
   
 }
 #col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #003393;
    padding: 2rem 1rem;
    
}
#text {
    
    display: contents;
    flex-direction: column;
    align-items: center;
    margin-top: 12rem;
    width: 100%;
   
   
   
}
#text div[class='buttondevis'] {
    order: 5;
}
#text h1 {
    /*color: aliceblue;
    font-size: 40px;
    font-size:2.3rem;
    margin-bottom: 10%;
   font-weight: 500;*/
   display: none;
}
#text p {
    margin-bottom:1rem;
    color: #00FFFF;
    /*font-size: 36px;*/
    font-size:1.8em;
    font-weight: 700;
    line-height: 2.5rem;
}
#text p span {
    font-style: italic;
}
#carte {
    display: flex;
    width: 100%;
    padding: 1rem 0rem 4rem 0rem ;
}
#carte img{
    width: 100%;
}

#Homesection3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top:2rem
}
.row1 {
    display: contents;
    width: 100%;
}
.row1 div[class='div36'] {
    order:-2;
    padding-bottom: 1rem;
}
.div36{
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}
.row1 p#objectif {
        color: red;
        font-size: 2.1rem;
        font-weight: 800;
        line-height: 2.8rem;
        padding-right: 2rem;
}
.row2 {
        display: contents;
        margin-top: 1rem;
}
.row2 div[class='div36'] {
    order:-1;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
p#one p#two {
        margin: 5rem 2rem 1rem 0rem;
        
} 
#one,#two {
    font-size: 1rem;
    font-weight: 500;
    padding-right: 2rem;
    padding-top: 0.5rem;
} 
.row3 {
        display: contents;
        margin-top: 1rem;
    
}
#row3img {
    order: 9;;
}

.bg {
    width: 100%;
    order: 9;
    padding: 0px;
    /*overflow: hidden;*/           
}

#Homesection4 {
    display: block;
    width: 100%;
    height: 350px;
    margin-bottom: 40vh;
}  
.Section4 {
    position: relative;
    background-image: url('../../assets/cover.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    
}
.cadrebleu {
    font-size: 1rem;
    color: #fff;
    background-color:#003393;
    padding: 3rem 2rem 3rem 2rem;
    border-radius: 2rem;
    margin: 1rem;
    position: absolute;
    bottom: -38vh;
    

}
.cadrebleu span {
    display: block;
   
    color: red;
    font-weight: 700;
    padding: 0.3rem 0rem;
}
.cadrebleu span:first-child {
    display: block;
    color: #fff;
    /*font-size: 0.3em;*/
    font-size: 1rem;
    line-height: 1rem;
    padding-bottom: 1rem;
}
.cadrebleu .send {
    display: block;
    color: #fff;
    font-size: 2rem;
    line-height: 2.7rem;
    padding-bottom: 1.2rem;
}
.cadrebleu .send br{
    display: contents
}

#Homesection5 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
}
.item1 {
    
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5rem;
    font-size: 2.4rem;

}
#Ibs{
    
    font-weight: 500;
    line-height: 3.2rem;
    letter-spacing: 2%;
    margin-bottom: 2rem;
    align-self: center;
    padding: 0px 1rem;
    
    
}

.item1 span#IBS {
    color: red;
    font-weight: 700;
}
.item2 {
    width: 100%; 
    padding:0rem 1rem;
      
}
.item2 img {
    width: 100%;  
    
}


#Homesection6 span {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: 700;

}
#Homesection6 span span {
    color: red;
}

#Homesection6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.rowimg1,.rowimg2{
    
    display: contents;
    
}
.rowimg1 img,.rowimg2 img{
    margin: 1rem 4rem;
   
    cursor: pointer;
}

#Homesection6 span {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: 700;

}
#Homesection6 span span {
    color: red;
}

#Homesection7 {
    background-color: #A1DAF8;
    
}
#blocbleu {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding:4rem  1rem 2rem 1rem ;
}
#blocbleu p {
    margin-bottom: 2rem;
   
    font-size: 1rem;
    font-weight: 500;
}
#Homesection8 {
    background-color: #003393;
    padding-top: 2rem;
    padding-bottom: 8rem;
}
#Homesection8_center {
    width: 100%;
    
}

.kati {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.kati h1 {
    font-size: 1.5rem;
    color: aliceblue;
    margin-bottom: 1rem;
    
 }
 .kati p {
    font-size: 1.2rem;
    width: 70%;
    text-align: center;
    color: aliceblue;
   padding-bottom: 2rem;

 }
 .flexrow {
    display: flex;
    width: 100%;
    justify-content:flex-start;
    overflow-x: scroll;
    padding: 0rem 4rem;
}
.flexrow::-webkit-scrollbar {
    background: transparent;
   
}
#Homesection9 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    
}
   
#Homesection9 h1 {
       padding-bottom: 1rem;
       font-size: 1.5rem;
} 
#Homesection9 p {
    width: 60%;
    text-align: center;
}
.flexpartenaire {
    display: flex;
    justify-content: center;
    align-items:center;
    margin-top: 1.2rem;
    width: 100%;
 }
.flexpartenaire img {
    margin: 1rem;
    overflow: hidden;
}

::-webkit-scrollbar{
    width: 0px;
}
::-webkit-scrollbar-track{
    background: transparent;
}


    
  }
  